@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&family=Poppins:wght@400;500;600&family=Shantell+Sans:wght@300;700&display=swap');


.custom-font {
    font-family: 'Nanum Gothic Coding', monospace;
}

.pixelated {
    image-rendering: pixelated;
}

  /* For WebKit browsers */
::-webkit-scrollbar {
    width: 12px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
  
@media (max-width: 767px) {
  .description {
    max-height: 3em; /* Adjust this value based on your design */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

::selection {
  background-color: #e3f2ff; 
  color: #1d242c; 
}

::-webkit-selection {
  background-color: #e3f2ff; 
  color: #1d242c;
}

/* CODE */
.code-select::selection {
  background-color: #ffffff; 
  color: rgb(0, 0, 0);
}

.code-select::-webkit-selection {
  background-color: #ffffff; 
  color: rgb(0, 0, 0);
}

